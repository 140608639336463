<template>
    <main class="error-404">
        <p class="error-404__text">Запрашиваемая вами страница не найдена 😿</p>
    </main>
</template>

<script>
    export default {
        name: "Error404"
    }
</script>

<style lang="scss">
    .error-404__text {
        &:not(:first-child) {
            margin-top: 10px;
        }
    }
</style>
